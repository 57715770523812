$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$tertier-color: #22D882;

.container {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 10rem;

  & > {
    div {
      &.table {
        display: flex;
        padding: 1rem;
        background-color: white;
        border-radius: .5rem;
        justify-content: space-between;

        div {
          &:nth-child(2) {
            text-align: end;
          }
        }
      }
    }
  }
}

.form {
  border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
  border-radius: .5rem;
  padding: 1rem;
  display: flex;
  flex-flow: column wrap;
  background: #F0F3F8;
  gap: 1rem;

  & > {
    div {
      &.upload {
        div {
          border: rgba($color: $smooth-grey, $alpha: .75) solid 1px;
          background-color: white;
          padding: .5rem;
          border-radius: .5rem;
        }
      }

      &.source {
        div {
          border: rgba($color: $smooth-grey, $alpha: .75) solid 1px;
          background-color: white;
          padding: .5rem;
          border-radius: .5rem;
        }
      }

      &.single {
        display: flex;
        flex-flow: column nowrap;

        input {
          padding: 1rem;
          border-radius: .5rem;
          border: 1px solid rgba($color: $smooth-grey, $alpha: .35);
          font-size: medium;

          &:focus {
            outline: 2px solid rgba($color: $primary-color, $alpha: .5);
          }

          &::placeholder {
            color: rgba($color: $smooth-grey, $alpha: .65);
          }
        }
      }
    }
  }
}