$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;

.statusUpload {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
  border-radius: .5rem;
  padding: .5rem;

  div {
    flex: 1 1 auto;
    padding: .5rem;
    text-align: center;

    + div {
      border-left: 1px solid rgba($color: $smooth-grey, $alpha: 0.4);
    }

    & > svg {
      font-size: 1.5rem;
      color: $primary-color;
    }

    & > p {
      margin: 0;
      color: $smooth-grey;
      font-size: .75rem;
    }
  }

  & > {
    div {
      &:not(:first-child) {
        svg {
          color: rgba($color: $smooth-grey, $alpha: 0.4);
        }
      }
    }
  }
}