$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$tertier-color: #22D882;

.npwp {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;

  & > {
    div {
      &:first-child {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
        border-radius: .5rem;
        padding: .5rem;

        div {
          + div {
            border-left: 1px solid rgba($color: $smooth-grey, $alpha: 0.4);
          }
        }
        

        & > {
          div {
            &:not(:first-child) {
              svg {
                color: rgba($color: $smooth-grey, $alpha: 0.4);
              }
            }
            &:nth-child(2) {
              svg {
                color: $primary-color;
              }
            }
          }
        }

        div {
          flex: 1 1 auto;
          padding: .5rem;
          text-align: center;

          & > {
            svg {
              font-size: 1.5rem;
              color: $tertier-color;
            }
          }

          & > {
            p {
              margin: 0;
              color: $smooth-grey;
              font-size: .75rem;
            }
          }
        }
      }

      &:nth-child(2) {
        flex-basis: calc(100% / 1.5);
        background: white;
        border-radius: .5rem;
        padding: 1rem;
        text-align: center;
        border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
        max-height: 28rem;

        img {
          object-fit: cover;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
        
        & > {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 15rem;
            border-radius: .5rem;
            border: 1px solid rgba($color: $smooth-grey, $alpha: .25);

            svg {
              color: $primary-color;
              font-size: 3rem !important;
            }
          }
        }
      }

      &:last-child {
        display: flex;
        // gap: 1rem;
        justify-content: center;
      }
    }
  }
}