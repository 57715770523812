$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$primary-green: #22D882;
$primary-red: #FF3B3B;
$bg-color: #F0F3F8;

.customer {
  position: relative;
  padding: 1rem 1.25rem;
  background: white;
  border-radius: .5rem;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  display: flex !important;
  flex-flow: column wrap !important;

  & > {
    div {
      &.container {
        display: flex;
        flex-flow: column wrap;
        gap: 1.5rem;

        & > {
          div {
            h2 {
              font-weight: 400;
            }
          }
          
          div.infoPortfolio {
            & > {
              h2 {
                font-weight: 400;
              }

              div {
                display: flex;
                gap: .5rem;
                padding: .5rem 0;
                flex-flow: column wrap;

                & > {
                  .account {
                    width: 100%;
                    background-color: $bg-color;
                    border-radius: .5rem;
                    border: rgba($color: $smooth-grey, $alpha: .25) solid 1px;
                    
                    & > {
                      
                      div {
                        padding: .5rem;
                        display: flex;
                        width: 100%;
                        gap: 1rem;

                        &:first-child {
                          background-color: rgba($color: $primary-color, $alpha: .15);
                          border-top-left-radius: .5rem;
                          border-top-right-radius: .5rem;
                          
                          h3 {
                            font-weight: 400;
                            color: $primary-grey;
                          }
                        }

                        &:last-child {
                          div {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            
                            &.wallet {
                              width: 10rem;
                              height: 5rem;
                              background-color: $primary-color;
                              color: white;
                              border-radius: .5rem;
                              display: flex;
                              justify-content: center;
                              align-items: center;

                              svg {
                                font-size: large;
                              }
                            }

                            &:last-child {
                              width: 100%;
                              display: flex;
                              gap: .5rem;

                              div {
                                display: flex;
                                flex-flow: column wrap;
                                align-items: flex-start;
                                gap: 0.2rem;
                                width: calc(100% / 3);

                                h3 {
                                  color: navy;
                                  font-weight: 500;
                                  width: 100%;
                                }

                                p {
                                  color: $smooth-grey;
                                }
                              }

                              p {
                                color: $primary-grey;
                                width: calc(100% / 3);
                              }
                              
                              h3 {
                                color: $primary-grey;
                                font-weight: 500;
                                width: calc(100% / 3);
                              }
                            }
                          }

                          + div {
                            & > {
                              div {
                                &:last-child {
                                  border-top: rgba($color: $smooth-grey, $alpha: .25) solid 1px;
                                }
                              }
                            }
                          }
                        } 
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  h3 {
    font-weight: 500;
    color: $primary-grey;
  }
}