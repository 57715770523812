$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;

.ktp {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;

  & > {
    div {
      &:nth-child(2) {
        flex-basis: calc(100% / 1.5);
        background: white;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        padding: 1rem;
        text-align: center;
        border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
        max-height: 28rem;

        img {
          object-fit: cover;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }

        & > {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 15rem;
            border-radius: .5rem;
            border: 1px solid rgba($color: $smooth-grey, $alpha: .25);

            svg {
              color: $primary-color;
              font-size: 3rem !important;
            }
          }
        }
      }

      

      &:nth-child(4) {
        display: flex;
        // gap: 1rem;
        justify-content: center;
      }
    }
  }
}


