$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;

.statusUpload {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
  border-radius: .5rem;
  padding: .5rem;

  & > {
    div {
      flex: auto 1 1;
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: start;
      height: 100%;

      & > {
        div {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          padding: .5rem;
          text-align: start;

          &:first-child {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            gap: .5rem;
            height: 25%;

            & > svg {
              font-size: 1.5rem;
              color: $primary-color;
            }

            & > p {
              margin: 0;
              color: $smooth-grey;
              font-size: .75rem;
            }
          }
          &:last-child {
            flex-grow: 1;

            div {
              display: flex;
              flex-flow: row nowrap;
              gap: .5rem;
              align-items: center;
            }
          }
          + div {
            border-top: 1px solid rgba($color: $smooth-grey, $alpha: .25);
          }
        }
      }
      + div {
        border-left: 1px solid rgba($color: $smooth-grey, $alpha: .25);
      }
    }
  }
}