$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$tertier-color: #22D882;

.forms {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;

  & > {
    div {
      &:first-child {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
        border-radius: .5rem;
        padding: .5rem;

        div {
          + div {
            border-left: 1px solid rgba($color: $smooth-grey, $alpha: 0.4);
          }
        }

        & > {
          div {
            &:not(:last-child) {
              svg {
                color: $tertier-color;
              }
            }
          }
        }

        & > {
          div {
            &:last-child {
              svg {
                color: $primary-color;
              }
            }
          }
        }

        div {
          flex: 1 1 auto;
          padding: .5rem;
          text-align: center;

          & > {
            svg {
              font-size: 1.5rem;
            }
          }

          & > {
              p {
              margin: 0;
              color: $smooth-grey;
              font-size: .75rem;
            }
          }
        }
      }

      &:nth-child(3) {
        display: flex;
        gap: 1rem;
        justify-content: end;
      }

      
    }
  }
}

.form {
  border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
  border-radius: .5rem;
  padding: 1rem;
  display: flex;
  flex-flow: column wrap;
  // background: #F0F3F8;
  gap: 1rem;

  & > {
    div {
      input, textarea {
        padding: 1rem;
        border-radius: .5rem;
        border: 1px solid rgba($color: $smooth-grey, $alpha: .35);
        font-size: medium;
        font-family: inherit;
      }

      input {
        &:focus {
          outline: 2px solid rgba($color: $primary-color, $alpha: .5);
        }

        &::placeholder {
          color: rgba($color: $smooth-grey, $alpha: .65);
        }
      }

      &.single {
        display: flex;
        flex-flow: column nowrap;
      }

      &.sparated {
        display: flex;
        flex-flow: row nowrap;
        gap: 1rem;
        justify-content: space-evenly;

        div {
          display: flex;
          flex-flow: column wrap;
          flex-grow: 1;          
        }
      }

      &.checker {
        display: flex;
        flex-flow: column nowrap;
        gap: .25rem;
        
        div {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: auto;
          gap: .5rem;

          & > {
            div {
              display: flex;
              align-items: center;
              gap: .5rem;

              & > {
                label {
                  color: $primary-grey;
                }
              }
            }
          }
        }
      }
      &.upload {
        display: flex;
        flex-flow: row nowrap;
        gap: .5rem;
        
        div {
          flex-basis: calc(100% / 3);
          border-radius: .5rem;
          background: white;
          display: flex;
          justify-content: center;
          padding: .5rem;
          text-align: center;
          border: 1px solid rgba($color: $smooth-grey, $alpha: 0.25);
          width: 100%;
          height: 100%;
          
          & > {
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }
        }
      }
      
      &.tc {
        display: flex;
        flex-flow: column nowrap;

        div {
          display: flex;
          gap: .5rem;
          align-items: center;

          label {
            color: $smooth-grey;
          }
        }
      }
    }
  }
}