$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;

.services {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;

  & > {
    div {
      display: flex;
      gap: 1rem;

      & > {
        div {
          width: calc( 100% / 2 );
          display: flex;
          flex-flow: column wrap;
          gap: 1rem;

          & > {
            button {
              background-color: rgba($color: $primary-color, $alpha: .1);
              // padding: 1.25rem 1rem;
              border-radius: .5rem;
              color: $primary-grey;
              display: flex;
              align-items: center;
              justify-content: space-between !important;
              overflow: hidden;
              padding-left: 1rem;
              height: 4rem;

              &:hover {
                background-color: rgba($color: $primary-color, $alpha: .2);
                transition: all 500ms;
              }

              & > {
                img {
                  position: relative;
                  height: 100%;
                  right: -2%;
                  min-width: 4rem;
                  object-fit: cover;
                  width: 5rem;
                  transform: skew(15deg);
                  border-left: .75rem solid $primary-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
