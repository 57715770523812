.loader {
  position: absolute;
  top: calc(50% - calc(5rem/2));
  left: calc(50% - calc(5rem/2));
  height: 3rem;
  width: 3rem;
  animation: spin 1000ms infinite linear;
  border-radius: 50%;
  border: {
    right: white dashed .3rem;
    bottom: dodgerblue solid .3rem;
    top: dodgerblue solid .3rem;
    left: dodgerblue solid .3rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
}