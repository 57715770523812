$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$primary-green: #22D882;
$primary-red: #FF3B3B;
$bg-color: #F0F3F8;

.confirmation {
  display: flex;
  flex-flow: column wrap !important;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  gap: 5rem !important;
  height: 80%;
  
  & > {
    div {
      display: flex;
      text-align: center;
      gap: 1rem;
      
      & > {
        button {
          padding: .75rem 1rem;
          min-width: 8rem;
          border-radius: .5rem;
          cursor: not-allowed;

          &:first-child {
            border: rgba($color: $primary-color, $alpha: .5) solid 1px;
          }

          &:last-child {
            background: $primary-color;
            color: white;
          }
        }
      }
    }
  }
}