$primary-green: #22D882;

.successReg {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 10rem;
  align-items: center;
  justify-content: center;

  & > {
    div {
      &:first-child {
        background: rgba($color: $primary-green, $alpha: .25);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        color: $primary-green;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: large;
        }
      }
    }
  }
}