*, body, body::after, body::before { 
  margin:0; 
  padding:0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F0F3F8;
}

h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 0 !important;
}

h1 {
  font-size: 2rem !important;
}

h2 {
  font-size: 1.75rem !important;
}

h3 {
  font-size: 1.5rem !important;
}

h4 {
  font-size: 1.25rem !important;
}

h5 {
  font-size: 1rem !important;
}

h6 {
  font-size: .75rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  all: unset;
  cursor: pointer;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba($color: #50A0FF, $alpha: 0.3) white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
  border-radius: .5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba($color: #50A0FF, $alpha: 0.3);
  border-radius: 20px;
  border: 3px solid white;
}