$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$bg-color: #F0F3F8;

.form {
  display: flex;
  flex-flow: column wrap;
  gap: 2rem;
  padding: 8rem 6rem;

  div {
    display: flex;
    flex-flow: row wrap;

    &:first-child, &:nth-child(2) {
      display: flex;
      flex-flow: column wrap;
      gap: .5rem;

      label {
        font-weight: 400;
        color: $primary-grey;
      }

      input {
        padding: 1rem;
        border-radius: .5rem;
        background: rgba($color: $bg-color, $alpha: .5);
        border: 1px solid rgba($color: $smooth-grey, $alpha: .35);

        &:focus {
          outline: 2px solid rgba($color: $primary-color, $alpha: .5);
        }

        &::placeholder {
          color: rgba($color: $smooth-grey, $alpha: .65);
        }
      }
    }
  }

  .wait {
    padding: 1rem;
    background: rgba($color: $bg-color, $alpha: .5);
    border: 1px solid rgba($color: $primary-color, $alpha: .35);
    border-radius: .5rem;
    color: $primary-color;
    text-align: center;
    cursor: not-allowed;
  }

  .active {
    padding: 1rem;
    background: $primary-color;
    border-radius: .5rem;
    color: white;
    text-align: center;

    &:hover {
      background: dodgerblue;
    }
  }
}