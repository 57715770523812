$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;

.service {
  margin-top: 1rem;
  overflow: hidden;
  border-radius: .5rem;

  button {
    background-color: rgba($color: $primary-color, $alpha: .1);
    color: $primary-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding-left: 2rem;
    font-size: large;
    width: 100%;

    &:hover {
      background-color: rgba($color: $primary-color, $alpha: .2);
      transition: all 500ms;
    }

    & > {
      img {
        position: relative;
        right: -2%;
        min-width: 8rem;
        width: 10rem;
        transform: skew(15deg);
        border-left: 1rem solid $primary-color;
      }
    }
  }
}

.tc {
  padding: 1rem 0;
  display: flex;
  flex-flow: column wrap;
  gap: 2rem !important;
  color: rgba($color: $primary-grey, $alpha: .75);

  &> {
    p {
      width: 100%;
    }

    ol {
      color: rgba($color: $primary-grey, $alpha: .9);
      padding-left: 1rem;
    }
  }

  div {
    display: flex;
    align-items: center;

    &:nth-last-child(2) {
      gap: .5rem;
      color: $primary-grey;

      input {
        background: $primary-grey;
      }
    }

    &:last-child {
      gap: 1rem;
      justify-content: end;
    }
  }
}