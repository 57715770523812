$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$primary-green: #22D882;
$primary-red: #FF3B3B;
$bg-color: #F0F3F8;

.successAct {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  
  & > {
    div {
      padding: 4rem;
      color: $primary-green;
      display: flex;
      flex-flow: column nowrap;
      text-align: center;
      gap: .5rem;
      flex-grow: 1;

      h1 {
        color: $primary-green;
        font-weight: 500;
      }

      p {
        color: $primary-grey;

        &:first-child {
          font-weight: 500;
        }

        &:nth-last-child(2) {
          font-size: small;
          color: $primary-red
        }
      }

      ul {
        list-style: none;
        border: 1px solid rgba($color: $smooth-grey, $alpha: .35);
        background-color: $bg-color;
        border-radius: .5rem;
        text-align: left;
        padding: 0 1rem;
        width: 75%;
        margin: 0 auto;
        margin-top: 1rem;

        li {
          color: $primary-grey;
          margin: 1rem 0;
          display: flex;

          span {
            &:first-child {
              width: calc( 100% / 3 );
            }
          }
        }
      }
    }

    button {
      margin-left: auto;
    }
  }
}