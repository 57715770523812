$primary-color: #50A0FF;
$smooth-grey: #989898;
$primary-grey: #54575C;
$primary-green: #22D882;
$primary-red: #FF3B3B;

.otp {
  display: flex;
  flex-flow: column nowrap !important;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 8rem;
  align-items: center;
  justify-content: center;

  & > {
    h4 {
      color: $primary-grey;
      font-weight: 500;
    }
    p {
      color: $smooth-grey;
    }

    div {
      &.inputOTP {
        border-radius: 50%;
        color: $primary-green;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;

        input {
          padding: 1rem;
          width: 5rem;
          height: 3rem;
          border-radius: .5rem;
          border: 1px solid rgba($color: $smooth-grey, $alpha: .35);
          text-align: center;
          font-size: 2rem;

          &:focus {
            outline: 2px solid rgba($color: $primary-color, $alpha: .5);
          }

          &::placeholder {
            color: rgba($color: $smooth-grey, $alpha: .65);
          }
        }
      }

      &.timer {
        background: rgba($color: $primary-red, $alpha: .25);
        padding: 1rem;
        border-radius: .5rem;
      }
    }
  }
}