$primary-color: #50a0ff;
$primary-green: #22d882;
$dark-green: #13ae66;
$primary-grey: #54575c;
$smooth-grey: #989898;

.tab {
  width: calc(100% / 3);
  border-radius: 0.65rem;
  background: rgba($color: $primary-color, $alpha: 0.25);
  display: flex !important;
  flex-flow: row nowrap !important;
  padding: 0.25rem;
  justify-content: center;
  margin-bottom: 1rem;
  display: block;

  button {
    border-radius: 0.5rem;
    padding: 0.7rem;
    width: calc(calc(100% - 2.8rem) / 2);
    text-align: center;
    color: #989898;
    font-size: small;
    font-weight: 400;

    &.active {
      background: white;
      color: $primary-grey;
      font-weight: 500;
    }
  }
}
