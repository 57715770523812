$primary-color: #50A0FF;
$primary-green: #22D882;
$dark-green: #13ae66;
$primary-grey: #54575C;
$smooth-grey: #989898;

@mixin navbar($isHide: false) {
  border-right: 1px solid rgba($color: $smooth-grey, $alpha: .25);
  background: white;

  @if $isHide {
    width: 66px; 
    animation: shrink 500ms ease-in-out;
  } @else {
    width: calc(100% / 6.5);
    animation: grow 500ms ease-in-out;
  }

  nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    
    ul.ul {
      height: 100%;
      position: relative;
      padding: 0 !important;

      & > {
        li {
          margin: 1rem 0;
          list-style: none;

          &:first-child {
            display: flex;
            align-items: center;

            &:hover {
              background: rgba($color: $primary-color, $alpha: .1);
            }

            button.home, button.hide {
              &:hover {
                background: none;
              }
            }

            button.home {
              @if $isHide {
                display: none;
              } @else {
                flex-grow: 1;
              }
            }

            button.hide {
              &.hide {
                height: 2.5rem;
                width: 2.5rem;
                background: $primary-color;
                color: white;
                border-radius: .5rem;
                display: grid;
                place-content: center;
                animation: 500ms ease-in-out;

                svg {
                  color: white;
                  @if $isHide {
                    display: block;
                  }
                }

                @if $isHide {
                  margin: .5rem auto;
                  animation: tl 500ms ease-in-out;
                } @else {
                  margin-right: .75rem;
                }
              }
            }
          }

          &.lastli {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
          
          button, a {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            gap: 1rem;
            box-sizing: border-box;
            padding: .5rem .75rem; 
            align-items: center;
            text-decoration: none;
            color: $primary-grey;

            & > {
              div {
                height: 2.5rem;
                min-width: 2.5rem;
                background: $primary-color;
                color: white;
                border-radius: .5rem;
                display: grid;
                place-content: center;
              }

              span {
                flex-grow: 1;
              }

              svg {
                @if $isHide {
                  display: none;
                }
              }

            }

            &:hover {
              background: rgba($color: $primary-color, $alpha: .1);
            }
          }

          ul.submenu {
            background: rgba($color: $smooth-grey, $alpha: .075);
            padding: .5rem 0;
            
            & > {
              li {
                padding: .5rem 0;
                & > {
                  button {
                    padding: 0 1.5rem;

                    &:hover {
                      background: none;
                      text-decoration: underline;
                      color: $primary-color;
                    }

                    svg {
                      font-size: x-small;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.show {
  @include navbar($isHide: false)
}

.hide {
  @include navbar($isHide: true)
}

@keyframes grow {
  0% {
    width: 66px;
  }

  100% {
    width: calc(100% / 6.5);
  }
}

@keyframes shrink {
  from {
    width: calc(100% / 6.5);
  }

  to {
    width: 66px;
  }
}

@keyframes tl {
  0% {
    margin-right: 1rem;
  }

  80% {
    margin-right: 1rem;
  }

  100% {
    margin: .5rem auto;
  }

}